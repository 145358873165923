.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.country-flag{
  width: 1.5em;
  height: 1.2em;
  margin: 0px 6px -2px 0px;
}
body {
  background-color: #eeeeee;
}

.country_Selected_flag {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}


.version_history_environment_type {
  background: #ceeef1;
  border-radius: 5px;
  padding: 2px;
  margin-right: 3px;
  border: 2px solid #ccc;
}